define("discourse/plugins/docuss/discourse/lib/bellhop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.BellhopEventDispatcher = _exports.Bellhop = void 0;
  function t(t, e) {
    return t(e = {
      exports: {}
    }, e.exports), e.exports;
  }
  var e = t(function (t) {
    function e(t) {
      return (e = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (t) {
        return typeof t;
      } : function (t) {
        return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
      })(t);
    }
    function r(n) {
      return "function" == typeof Symbol && "symbol" === e(Symbol.iterator) ? t.exports = r = function (t) {
        return e(t);
      } : t.exports = r = function (t) {
        return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : e(t);
      }, r(n);
    }
    t.exports = r;
  });
  var r = function (t, e) {
    if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function");
  };
  function n(t, e) {
    for (var r = 0; r < e.length; r++) {
      var n = e[r];
      n.enumerable = n.enumerable || !1, n.configurable = !0, "value" in n && (n.writable = !0), Object.defineProperty(t, n.key, n);
    }
  }
  var o = function (t, e, r) {
      return e && n(t.prototype, e), r && n(t, r), t;
    },
    i = _exports.BellhopEventDispatcher = function () {
      function t() {
        r(this, t), this._listeners = {};
      }
      return o(t, [{
        key: "on",
        value: function (t, e) {
          var r = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : 0;
          this._listeners[t] || (this._listeners[t] = []), e._priority = parseInt(r) || 0, -1 === this._listeners[t].indexOf(e) && (this._listeners[t].push(e), this._listeners[t].length > 1 && this._listeners[t].sort(this.listenerSorter));
        }
      }, {
        key: "listenerSorter",
        value: function (t, e) {
          return t._priority - e._priority;
        }
      }, {
        key: "off",
        value: function (t, e) {
          if (void 0 !== this._listeners[t]) if (void 0 !== e) {
            var r = this._listeners[t].indexOf(e);
            -1 < r && this._listeners[t].splice(r, 1);
          } else delete this._listeners[t];
        }
      }, {
        key: "trigger",
        value: function (t) {
          var r = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
          if ("string" == typeof t && (t = {
            type: t,
            data: "object" === e(r) && null !== r ? r : {}
          }), void 0 !== this._listeners[t.type]) for (var n = this._listeners[t.type].length - 1; n >= 0; n--) this._listeners[t.type][n](t);
        }
      }, {
        key: "destroy",
        value: function () {
          this._listeners = {};
        }
      }]), t;
    }(),
    s = t(function (t) {
      var e = function (t) {
        var e,
          r = Object.prototype,
          n = r.hasOwnProperty,
          o = "function" == typeof Symbol ? Symbol : {},
          i = o.iterator || "@@iterator",
          s = o.asyncIterator || "@@asyncIterator",
          a = o.toStringTag || "@@toStringTag";
        function c(t, e, r, n) {
          var o = e && e.prototype instanceof v ? e : v,
            i = Object.create(o.prototype),
            s = new j(n || []);
          return i._invoke = function (t, e, r) {
            var n = h;
            return function (o, i) {
              if (n === l) throw new Error("Generator is already running");
              if (n === p) {
                if ("throw" === o) throw i;
                return P();
              }
              for (r.method = o, r.arg = i;;) {
                var s = r.delegate;
                if (s) {
                  var a = O(s, r);
                  if (a) {
                    if (a === y) continue;
                    return a;
                  }
                }
                if ("next" === r.method) r.sent = r._sent = r.arg;else if ("throw" === r.method) {
                  if (n === h) throw n = p, r.arg;
                  r.dispatchException(r.arg);
                } else "return" === r.method && r.abrupt("return", r.arg);
                n = l;
                var c = u(t, e, r);
                if ("normal" === c.type) {
                  if (n = r.done ? p : f, c.arg === y) continue;
                  return {
                    value: c.arg,
                    done: r.done
                  };
                }
                "throw" === c.type && (n = p, r.method = "throw", r.arg = c.arg);
              }
            };
          }(t, r, s), i;
        }
        function u(t, e, r) {
          try {
            return {
              type: "normal",
              arg: t.call(e, r)
            };
          } catch (t) {
            return {
              type: "throw",
              arg: t
            };
          }
        }
        t.wrap = c;
        var h = "suspendedStart",
          f = "suspendedYield",
          l = "executing",
          p = "completed",
          y = {};
        function v() {}
        function d() {}
        function g() {}
        var m = {};
        m[i] = function () {
          return this;
        };
        var w = Object.getPrototypeOf,
          b = w && w(w(S([])));
        b && b !== r && n.call(b, i) && (m = b);
        var _ = g.prototype = v.prototype = Object.create(m);
        function x(t) {
          ["next", "throw", "return"].forEach(function (e) {
            t[e] = function (t) {
              return this._invoke(e, t);
            };
          });
        }
        function L(t) {
          var e;
          this._invoke = function (r, o) {
            function i() {
              return new Promise(function (e, i) {
                !function e(r, o, i, s) {
                  var a = u(t[r], t, o);
                  if ("throw" !== a.type) {
                    var c = a.arg,
                      h = c.value;
                    return h && "object" == typeof h && n.call(h, "__await") ? Promise.resolve(h.__await).then(function (t) {
                      e("next", t, i, s);
                    }, function (t) {
                      e("throw", t, i, s);
                    }) : Promise.resolve(h).then(function (t) {
                      c.value = t, i(c);
                    }, function (t) {
                      return e("throw", t, i, s);
                    });
                  }
                  s(a.arg);
                }(r, o, e, i);
              });
            }
            return e = e ? e.then(i, i) : i();
          };
        }
        function O(t, r) {
          var n = t.iterator[r.method];
          if (n === e) {
            if (r.delegate = null, "throw" === r.method) {
              if (t.iterator.return && (r.method = "return", r.arg = e, O(t, r), "throw" === r.method)) return y;
              r.method = "throw", r.arg = new TypeError("The iterator does not provide a 'throw' method");
            }
            return y;
          }
          var o = u(n, t.iterator, r.arg);
          if ("throw" === o.type) return r.method = "throw", r.arg = o.arg, r.delegate = null, y;
          var i = o.arg;
          return i ? i.done ? (r[t.resultName] = i.value, r.next = t.nextLoc, "return" !== r.method && (r.method = "next", r.arg = e), r.delegate = null, y) : i : (r.method = "throw", r.arg = new TypeError("iterator result is not an object"), r.delegate = null, y);
        }
        function E(t) {
          var e = {
            tryLoc: t[0]
          };
          1 in t && (e.catchLoc = t[1]), 2 in t && (e.finallyLoc = t[2], e.afterLoc = t[3]), this.tryEntries.push(e);
        }
        function k(t) {
          var e = t.completion || {};
          e.type = "normal", delete e.arg, t.completion = e;
        }
        function j(t) {
          this.tryEntries = [{
            tryLoc: "root"
          }], t.forEach(E, this), this.reset(!0);
        }
        function S(t) {
          if (t) {
            var r = t[i];
            if (r) return r.call(t);
            if ("function" == typeof t.next) return t;
            if (!isNaN(t.length)) {
              var o = -1,
                s = function r() {
                  for (; ++o < t.length;) if (n.call(t, o)) return r.value = t[o], r.done = !1, r;
                  return r.value = e, r.done = !0, r;
                };
              return s.next = s;
            }
          }
          return {
            next: P
          };
        }
        function P() {
          return {
            value: e,
            done: !0
          };
        }
        return d.prototype = _.constructor = g, g.constructor = d, g[a] = d.displayName = "GeneratorFunction", t.isGeneratorFunction = function (t) {
          var e = "function" == typeof t && t.constructor;
          return !!e && (e === d || "GeneratorFunction" === (e.displayName || e.name));
        }, t.mark = function (t) {
          return Object.setPrototypeOf ? Object.setPrototypeOf(t, g) : (t.__proto__ = g, a in t || (t[a] = "GeneratorFunction")), t.prototype = Object.create(_), t;
        }, t.awrap = function (t) {
          return {
            __await: t
          };
        }, x(L.prototype), L.prototype[s] = function () {
          return this;
        }, t.AsyncIterator = L, t.async = function (e, r, n, o) {
          var i = new L(c(e, r, n, o));
          return t.isGeneratorFunction(r) ? i : i.next().then(function (t) {
            return t.done ? t.value : i.next();
          });
        }, x(_), _[a] = "Generator", _[i] = function () {
          return this;
        }, _.toString = function () {
          return "[object Generator]";
        }, t.keys = function (t) {
          var e = [];
          for (var r in t) e.push(r);
          return e.reverse(), function r() {
            for (; e.length;) {
              var n = e.pop();
              if (n in t) return r.value = n, r.done = !1, r;
            }
            return r.done = !0, r;
          };
        }, t.values = S, j.prototype = {
          constructor: j,
          reset: function (t) {
            if (this.prev = 0, this.next = 0, this.sent = this._sent = e, this.done = !1, this.delegate = null, this.method = "next", this.arg = e, this.tryEntries.forEach(k), !t) for (var r in this) "t" === r.charAt(0) && n.call(this, r) && !isNaN(+r.slice(1)) && (this[r] = e);
          },
          stop: function () {
            this.done = !0;
            var t = this.tryEntries[0].completion;
            if ("throw" === t.type) throw t.arg;
            return this.rval;
          },
          dispatchException: function (t) {
            if (this.done) throw t;
            var r = this;
            function o(n, o) {
              return a.type = "throw", a.arg = t, r.next = n, o && (r.method = "next", r.arg = e), !!o;
            }
            for (var i = this.tryEntries.length - 1; i >= 0; --i) {
              var s = this.tryEntries[i],
                a = s.completion;
              if ("root" === s.tryLoc) return o("end");
              if (s.tryLoc <= this.prev) {
                var c = n.call(s, "catchLoc"),
                  u = n.call(s, "finallyLoc");
                if (c && u) {
                  if (this.prev < s.catchLoc) return o(s.catchLoc, !0);
                  if (this.prev < s.finallyLoc) return o(s.finallyLoc);
                } else if (c) {
                  if (this.prev < s.catchLoc) return o(s.catchLoc, !0);
                } else {
                  if (!u) throw new Error("try statement without catch or finally");
                  if (this.prev < s.finallyLoc) return o(s.finallyLoc);
                }
              }
            }
          },
          abrupt: function (t, e) {
            for (var r = this.tryEntries.length - 1; r >= 0; --r) {
              var o = this.tryEntries[r];
              if (o.tryLoc <= this.prev && n.call(o, "finallyLoc") && this.prev < o.finallyLoc) {
                var i = o;
                break;
              }
            }
            i && ("break" === t || "continue" === t) && i.tryLoc <= e && e <= i.finallyLoc && (i = null);
            var s = i ? i.completion : {};
            return s.type = t, s.arg = e, i ? (this.method = "next", this.next = i.finallyLoc, y) : this.complete(s);
          },
          complete: function (t, e) {
            if ("throw" === t.type) throw t.arg;
            return "break" === t.type || "continue" === t.type ? this.next = t.arg : "return" === t.type ? (this.rval = this.arg = t.arg, this.method = "return", this.next = "end") : "normal" === t.type && e && (this.next = e), y;
          },
          finish: function (t) {
            for (var e = this.tryEntries.length - 1; e >= 0; --e) {
              var r = this.tryEntries[e];
              if (r.finallyLoc === t) return this.complete(r.completion, r.afterLoc), k(r), y;
            }
          },
          catch: function (t) {
            for (var e = this.tryEntries.length - 1; e >= 0; --e) {
              var r = this.tryEntries[e];
              if (r.tryLoc === t) {
                var n = r.completion;
                if ("throw" === n.type) {
                  var o = n.arg;
                  k(r);
                }
                return o;
              }
            }
            throw new Error("illegal catch attempt");
          },
          delegateYield: function (t, r, n) {
            return this.delegate = {
              iterator: S(t),
              resultName: r,
              nextLoc: n
            }, "next" === this.method && (this.arg = e), y;
          }
        }, t;
      }(t.exports);
      try {
        regeneratorRuntime = e;
      } catch (t) {
        Function("r", "regeneratorRuntime = r")(e);
      }
    });
  var a = function (t) {
    if (void 0 === t) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    return t;
  };
  var c = function (t, r) {
      return !r || "object" !== e(r) && "function" != typeof r ? a(t) : r;
    },
    u = t(function (t) {
      function e(r) {
        return t.exports = e = Object.setPrototypeOf ? Object.getPrototypeOf : function (t) {
          return t.__proto__ || Object.getPrototypeOf(t);
        }, e(r);
      }
      t.exports = e;
    });
  var h = function (t, e) {
      for (; !Object.prototype.hasOwnProperty.call(t, e) && null !== (t = u(t)););
      return t;
    },
    f = t(function (t) {
      function e(r, n, o) {
        return "undefined" != typeof Reflect && Reflect.get ? t.exports = e = Reflect.get : t.exports = e = function (t, e, r) {
          var n = h(t, e);
          if (n) {
            var o = Object.getOwnPropertyDescriptor(n, e);
            return o.get ? o.get.call(r) : o.value;
          }
        }, e(r, n, o || r);
      }
      t.exports = e;
    }),
    l = t(function (t) {
      function e(r, n) {
        return t.exports = e = Object.setPrototypeOf || function (t, e) {
          return t.__proto__ = e, t;
        }, e(r, n);
      }
      t.exports = e;
    });
  var p = function (t, e) {
      if ("function" != typeof e && null !== e) throw new TypeError("Super expression must either be null or a function");
      t.prototype = Object.create(e && e.prototype, {
        constructor: {
          value: t,
          writable: !0,
          configurable: !0
        }
      }), e && l(t, e);
    },
    y = _exports.Bellhop = function (t) {
      function n() {
        var t,
          e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 100 * Math.random() | 0;
        return r(this, n), (t = c(this, u(n).call(this))).id = "BELLHOP:".concat(e), t.connected = !1, t.isChild = !0, t.connecting = !1, t.origin = "*", t._sendLater = [], t.iframe = null, t.receive = t.receive.bind(a(t)), t;
      }
      return p(n, i), o(n, [{
        key: "receive",
        value: function (t) {
          if (this.target === t.source) if ("connected" === t.data) this.onConnectionReceived(t.data);else {
            var r = t.data;
            if ("string" == typeof r) try {
              r = JSON.parse(r);
            } catch (t) {
              console.error("Bellhop error: ", t);
            }
            this.connected && "object" === e(r) && r.type && this.trigger(r);
          }
        }
      }, {
        key: "onConnectionReceived",
        value: function (t) {
          this.connecting = !1, this.connected = !0, this.isChild || this.target.postMessage(t, this.origin);
          for (var e = 0; e < this._sendLater.length; e++) {
            var r = this._sendLater[e],
              n = r.type,
              o = r.data;
            this.send(n, o);
          }
          this._sendLater.length = 0, this.trigger("connected");
        }
      }, {
        key: "connect",
        value: function (t) {
          var e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "*";
          this.connecting || (this.disconnect(), this.connecting = !0, t instanceof HTMLIFrameElement && (this.iframe = t), this.isChild = void 0 === t, this.supported = !0, this.isChild && (this.supported = window != t), this.origin = e, window.addEventListener("message", this.receive), this.isChild && (window === this.target ? this.trigger("failed") : this.target.postMessage("connected", this.origin)));
        }
      }, {
        key: "disconnect",
        value: function () {
          this.connected = !1, this.connecting = !1, this.origin = null, this.iframe = null, this.isChild = !0, this._sendLater.length = 0, window.removeEventListener("message", this.receive);
        }
      }, {
        key: "send",
        value: function (t) {
          var e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
          if ("string" != typeof t) throw "The event type must be a string";
          var r = {
            type: t,
            data: e
          };
          this.connecting ? this._sendLater.push(r) : this.target.postMessage(JSON.stringify(r), this.origin);
        }
      }, {
        key: "fetch",
        value: function (t, e) {
          var r = this,
            n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {},
            o = arguments.length > 3 && void 0 !== arguments[3] && arguments[3];
          if (!this.connecting && !this.connected) throw "No connection, please call connect() first";
          var i = function t(n) {
            o && r.off(n.type, t), e(n);
          };
          this.on(t, i), this.send(t, n);
        }
      }, {
        key: "respond",
        value: function (t) {
          var e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
            r = arguments.length > 2 && void 0 !== arguments[2] && arguments[2],
            n = this,
            o = function t(o) {
              var i;
              return s.async(function (a) {
                for (;;) switch (a.prev = a.next) {
                  case 0:
                    return r && n.off(o, t), "function" == typeof e && (e = e()), a.next = 4, s.awrap(e);
                  case 4:
                    i = a.sent, n.send(o.type, i);
                  case 6:
                  case "end":
                    return a.stop();
                }
              });
            };
          this.on(t, o);
        }
      }, {
        key: "destroy",
        value: function () {
          f(u(n.prototype), "destroy", this).call(this), this.disconnect(), this._sendLater.length = 0;
        }
      }, {
        key: "target",
        get: function () {
          return this.isChild ? window.parent : this.iframe.contentWindow;
        }
      }]), n;
    }();
});